.application-list {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .application-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .application-list th, .application-list td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .application-list th {
    background-color: #f4f4f4;
  }
  
  .application-list tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .application-list tr:hover {
    background-color: #f1f1f1;
  }
  
  .application-list .no-applications {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #777;
  }

  