.flex {
	display: flex;
}
*::-webkit-scrollbar{

	display: none;
}
.no_overflow {
	overflow-y: hidden;
	min-width: 100vw;
	min-height: 100vh;
}
.green {
	color: green;
}
.red {
	color: red;
}

.data-overflow {
	width: 86vw;
	max-height: 89vh;
	overflow: auto;
}
