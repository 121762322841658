.job-details-container {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.job-details {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5vw;
  max-height: 80vh;
  overflow: auto;
}

.job-details h1 {
  text-align: center;
  margin-bottom: 20px;
}

.job-details hr {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  gap: 30px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}


.form-group input[type="checkbox"] {
  margin-left: 10px;
}

.form-group.buttons {
  display: flex;
  justify-content: space-between;
}

.form-group.buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.form-group.buttons button:hover {
  opacity: 0.9;
}

.message {
  color: green;
  text-align: center;
  margin-bottom: 20px;
}

.red{
  color: red;
}