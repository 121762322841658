.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 28vw;
}

.client-signup label {
	font-weight: 400 !important;
}

.form input,
.form select {
	margin: 5px;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.form-act-btns {
	display: flex;
	align-items: baseline;
	justify-content: flex-end;
	gap: 32px;
	button {
		height: 40px;
		width: 100%;
	}
}
.heading1 {
	font-style: normal;
	color: var(--color-primary);
	font-size: 32px;
	font-weight: 500;
}

.form_and_img {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-left: auto;
	padding-right: auto;
}

.client-signup {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 32px;
}

.forgot_pwd {
	display: flex;
}

.client-signup button span {
	font-weight: 400 !important;
	padding: 10px 10px !important;
}

.form button:hover {
	background-color: #0056b3;
}

.topnav {
	background-color: #001744;
	width: 100%;
	height: 100px !important;
}

.topnav img {
	width: auto;
	height: 90px;
}
.content-section {
	height: calc(100vh - 100px);
	overflow: auto;
}
@media (max-width: 768px) {
	.client-signup .ant-form {
		width: 100%;
		padding: 24px;
	}
}
.home_img img {
	width: 35vw;
}

.no_overflow {
	overflow-y: hidden;
	max-width: 100%;
	max-height: 100vh;
}

.left_form {
	width: 30vw;
	padding: 20px;
}

.input_width {
	width: 28vw;
}

@media (max-width: 768px) {
	.input_width {
		width: 100%;
		max-width: 400px;
	}
	.heading1 {
		font-size: 16px;
	}
}

@media (max-width: 768px) {
	.container-signup {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	.form_and_img {
		display: inline;
	}
	#login_form {
		width: 100%;
		padding: 24px;
	}
	.home_img {
		display: none;
	}

	.login-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 0;
		max-width: 400px;
	}
}
