/* YourStyles.module.css */

.interviewersTable {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .interviewersTable th,
  .interviewersTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .interviewersTable th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .interviewersTable tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .interviewersTable tr:hover {
    background-color: #f1f1f1;
  }
  