.menu {
  background-color: #F8F8F8;
  margin-top: 20px;
  margin-bottom: 3px;
  border: none;
}

.red {
  display: flex;
  justify-content: center;
}


/* Job details component */

.jobs_details {
  background-color: #F8F8F8;
  padding-left: 10px;
}

.jobs_details .heading {
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  padding-left: 0px;
  /* background-color: #F8F8F8; */
}

.jobs_details .heading h5 {
  color: rgba(23, 26, 31, 1);
  font-weight: 700;
  font-size: 30px;
  /* margin-top: -30px; */
}

.jobs_details .details {
  display: flex;
  color: rgba(50, 56, 66, 1);
  background-color: white;
  border-radius: 15px;
}

.jobs_details .details .index {
  margin: 25px 10px;
  min-width: 15%;
  border-right: 1px solid rgba(188, 193, 202, 1);
  display: flex;
  flex-direction: column;
}

.jobs_details .details .index .title {
  font-size: 10px;
  font-weight: 400;
}

.jobs_details .details .index .val {
  font-size: 28px;
  font-weight: 600;
  margin-top: 5px;
}

.jobs_details .details .selected {
  color: rgba(22, 129, 255, 1);
  background-color: white;
}

.jobs_details .details .last-index {
  border-right: none;
}

/* job card component  */

.card {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgba(188, 193, 202, 1);
  border-radius: 12px;
  margin-top: 10px;
  color: rgba(23, 26, 31, 1);
}


.card .headers {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  margin-top: 10px;
}

.card .headers .first,
.last {
  display: flex;
  gap: 10px;
  align-items: center;
}

.card .headers .first .role {
  font-weight: 400;
  ;
}

.card .headers .first .status {
  font-weight: 400;
  color: rgba(55, 154, 230, 1);
  background-color: rgba(241, 248, 253, 1);
  font-size: 12px;
  padding: 5px 5px;
  border-radius: 14px;
  margin-left: -3px;
  /* margin-top: -3px; */
  ;
}

.card .headers .last {
  font-size: 11px;
}

.card .headers .last .edit {
  color: rgba(22, 129, 255, 1);
  display: flex;
  align-items: center;
  gap: 3px;
}


.card .headers .last .view-job {
  background-color: rgba(242, 242, 253, 1);
  color: rgba(22, 129, 255, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.card .headers .last .view-candidates {
  background-color: rgba(22, 129, 255, 1);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 3px 10px
}


.card .main-section {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  /* margin-top: 10px; */
}

.card .main-section .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 75px;
}

.card .main-section .item .client-name {
  font-size: 14px;
  font-weight: 600;

}

.card .main-section .boxes {
  display: flex;
  overflow-x: auto;
  margin-right: 20px;
  white-space: nowrap;
  padding: 10px 0;
  gap: 20px;
  max-width: 100%;
}

.card .main-section .box {
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: rgba(248, 249, 250, 1);
  box-shadow: 1px 1px 2px rgba(188, 193, 202, 1);
  border-radius: 6px;
  font-size: 14px;
  color: rgba(50, 56, 66, 1);
  flex-shrink: 0;
  scrollbar-width: thin;
  /* Makes scrollbar thinner in Firefox */
  scrollbar-color: rgba(22, 129, 255, 1) transparent;
}

.card .main-section .boxes::-webkit-scrollbar {
  height: 3px;
  /* Scrollbar thickness */
}

.card .main-section .boxes::-webkit-scrollbar-thumb {
  background-color: rgba(22, 129, 255, 1);
  /* Scrollbar color */
  border-radius: 5px;
  /* Rounded edges */
}

.card .main-section .boxes::-webkit-scrollbar-track {
  background: transparent;
  /* Track remains transparent */
}

.card .main-section .box .title {
  font-weight: 600;
}

.card .main-section .box .query {
  font-weight: 300;
  font-size: 12px;
}

.card .main-section .selected {
  background-color: rgba(117, 224, 100, 1);
}

.card .main-section .rejected {
  background-color: rgba(255, 156, 171, 1);
}