.client-dashboard .sec-1{
    display: flex;
    justify-content: space-between;
}

.client-dashboard .sec-1 a{
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    padding: 5px 10px;
    border-radius: 10px;
}

.client-dashboard .sec-1 div{
    font-size: 20px;
    font-weight: 500;
}

.client-dashboard .job-postings{
    margin: 20px;
}