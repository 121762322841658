.error {
  color: red;
  margin-bottom: 16px;
  text-align: center;
}

button[type="button"] {
  margin-top: 10px;
}

p {
  margin-top: 16px;
  text-align: center;
}

p > button {
  color: #1976d2;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  text-decoration: underline;
}

.container-signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  overflow: hidden;
}

.topnav {
  background-color: #001744;
  height: 80px;
  width: 100%;
}

.login-input{
  margin-bottom: 0px;
}

.role_buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.role_buttons button{
  width: 100%;
  height: 40px;
}

.role_buttons .centered{
  padding: 0px 50px 0px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}