.form {
  max-width: 100vw;
  margin: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.login-input {
  margin-bottom: 16px;
  height: 40px;
}

.error {
  color: red;
  margin-bottom: 16px;
  text-align: center;
}

button[type="button"] {
  margin-top: 8px;
}

p {
  margin-top: 16px;
  text-align: center;
}

p > button {
  color: #1976d2;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  text-decoration: underline;
}


h1{
  color: rgb(4, 4, 153);
  text-align: center; 
}

.login-button{
  background-color: #001744;
  margin-left: 0px;
}

.signup-text{
  color: black;
}