.interview-card {
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* gap: 16px; */
  background-color: #EBF3FF;
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  border-right: 3px solid #1b5cbe4c;
}
.time-slot {
  color: #1A56DB;
  font-size: 12px;
  font-weight: 500;
  min-width: 45px;
}

.interview-details {
  padding-left: 5px;
  border-left: 1px solid #1A56DB;
  color: #1E429F;
  font-size: 11px;
  font-weight: 600;
}

.candidate-name {
  font-weight: 500;
  margin-right: 4px;
}

.position {
  margin-right: 4px;
}

.interview-phase {
  margin-right: 4px;
}

.interview-time {
  color: #2D5AC7;
}