.steps-container {
	margin-bottom: 32px;
}

.companyterms p {
	text-align: left;
}


.footer-btns{
	display : flex;
	justify-content: space-between;
}

.footer-btns button{
	background-color: var(--color-primary);
}

.years-diff{
	gap: 20px;
}