.application-table {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-x: auto;
}

.jobInfo {
	text-align: center;
}

.jobInfo p {
	text-align: left;
	color: black;
}

.centered {
	text-align: center;
}

.row {
	display: flex;
	width: 100%;
}

.cell {
	flex: 1;
	min-width: 350px;
	padding: 8px;
	border: 1px solid #ddd;
	white-space: nowrap;
	font-size: 16px;
}

.cell:first-child {
	background-color: #f4f4f4;
	font-weight: 500;
	position: sticky;
	left: 0;
	z-index: 2;
}

.cell .action-btns .ant-btn span {
	font-size: 14px;
	font-weight: 500;
}
.comparison-cards-container{
	display: flex;
	gap: 50px;

}
.comparison-card{
	 border: 1px solid gray;
	 border-radius: 8px;
	 /* background-color: lightgreen; */
	 padding: 50px;
	 .close-button{
		display: flex;
		justify-content: flex-end;
		text-align: right;
		color: #ff0000;
	 }
}

.resumes-main textarea {
	height: 150px;
}

@media (max-width: 768px) {
	.cell {
		padding: 4px;
		font-size: 12px;
	}
}

@media (max-width: 480px) {
	.cell {
		padding: 2px;
		font-size: 10px;
	}
}

.bold {
	font-weight: bold;
	color: black;
	font-size: 1rem;
}

.buttons {
	display: flex;
	gap: 30px;
}

.cell form textarea {
	padding: 10px;
	/* font-size: 1rem; */
}

.cell form {
	display: flex;
	flex-direction: column;
}