.interview-details-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.interview-details p {
    margin-top: 0;
}

.candidate-actions {
    display: flex;
    gap: 30px;
}

.interview-details .accept-form,
.interview-details .reject-form,
form {
    margin-top: 30px;
}