.top-component {
    display: flex;
}

/* component 1 */

.profile {
    width: 30%;
    padding-top: 20px;
}

.profile .main-profile {
    display: flex;
    gap: 20px;
}

.profile .main-profile .profile-pic {}

.profile .main-profile .profile-details {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.profile .main-profile .profile-details .name {
    color: rgba(79, 79, 79, 1);
    font-size: 18px;
    font-weight: 700;
}

.profile .main-profile .profile-details .contact {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    color: rgba(146, 151, 166, 1);
    font-weight: 500;
    font-size: 12px;
}

.profile .main-profile .profile-details .gap {
    margin-left: 5px;
}

.profile .main-profile .profile-details .contact .phone {
    /* margin-top: 10px; */
}

.profile .main-profile .profile-details .contact .location {
    margin-top: 5px;
}

.profile .main-profile .profile-details .email {
    margin-top: 5px;
    color: rgba(146, 151, 166, 1);
    font-weight: 500;
    font-size: 12px;
}

.profile .profile-completion {
    margin-top: 15px;
}

.profile .profile-completion .profile-completion-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
}

.profile .profile-completion .profile-completion-flex .title {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    font-size: 14px;

}

.profile .profile-completion .profile-completion-flex .edit {
    color: rgba(64, 123, 255, 1);
    font-weight: 400;
    size: 16px;

}

.profile .profile-completion .line {
    margin-top: 15px;
    width: 80%;
    background-color: rgba(230, 230, 230, 1);
    height: 1px;
    display: flex;
}

.profile .profile-completion .line .handle-line {
    width: 74%;
    height: 10px;
    background: linear-gradient(to right, rgba(128, 163, 255, 1), rgba(1, 33, 115, 1));
    position: relative;
    top: -5px;
    border-radius: 16px;
}

.profile .profile-completion .line .box {
    position: relative;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -18px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    left: -3px;

}

.profile .profile-completion .line .box .percent {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-size: 11px;
}



.details-box {
    margin-top: 40px;
    background-color: rgb(236, 235, 245);
    border-radius: 6px;
    width: 100%;
    padding-bottom: 10px;
}

.details-box .details-box-item {
    /* height: 45px; */
    border-bottom: 1px solid rgb(195, 193, 193);
    display: flex;
    align-items: center;
    gap: 20px;
    /* justify-content: space-between; */
}

.details-box .query {
    margin-left: 20px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-size: 14px;
    flex: 0 0 80px;
}

.details-box .res {
    color: rgba(24, 25, 28, 1);
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.details-box .res .experience {
    display: flex;
    flex-direction: column;
}

.details-box .res .experience .duration {
    font-size: 12px;
    color: rgba(165, 170, 181, 1)
}

.details-box .documents {
    border: 1px solid rgba(165, 170, 181, 1);
    margin: 10px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 1);
    padding-bottom: 5px;
    /* margin-bottom: 20px; */
}

.details-box .documents-title {
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    color: rgba(116, 116, 116, 1);
    font-weight: 400;
    font-size: 14px;
}

.details-box .documents-title .download {
    cursor: pointer;
}

.details-box .line {
    margin-top: 0px;
    width: 100%;
    background-color: rgba(230, 230, 230, 1);
    height: 1px;
    display: flex;
}

.details-box .document-items {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    margin-left: 15px;
    /* justify-content: space-evenly; */
}



.details-box .document-item {
    border: 2px solid rgba(0, 91, 190, 1);
    align-items: center;
    padding: 5px 10px 5px 10px;
    color: rgba(0, 91, 190, 1);
    border-radius: 4px;
    cursor: pointer;
}

.details-box .document-status {
    margin-top: 15px;
    margin-left: 200px;
}

/* .details-box .verified,
.not-verified {
    display: flex;
    gap: 6px;
    align-items: center;
    color: rgba(135, 137, 125, 1);
    font-size: 14px;
    font-weight: 400;
} */

/* .details-box .blue-circle,
.red-circle {
    height: 13px;
    width: 13px;
    background-color: rgba(67, 100, 217, 1);
    border-radius: 50% 50%;
}

.details-box .red-circle {
    background-color: rgba(214, 40, 40, 1);
} */

.details-box .document-status .download {
    position: relative;
    right: -30px;
    color: rgba(64, 123, 255, 1);
    cursor: pointer;
}



/* component 2 */

.job_details {
    width: 45%;
    margin-left: 20px;
    margin-top: 30px;
}

.job_details .applied_jobs {
    /* height: 40px; */
    border: 2px solid rgba(229, 229, 229, 1);
    align-items: center;
    display: flex;
    gap: 10px;
    padding-left: 10px;
    border-radius: 5px;
}

.job_details .applied_jobs .title {
    border-right: 2px solid rgba(229, 229, 229, 1);
    height: 40px;
    display: flex;
    padding-right: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}


.job_details .applied_jobs .dropdown select {
    font-weight: 300;
    border: none;
    background-color: #F8F8F8;
    outline: none;
    cursor: pointer;
    position: relative;
    /* appearance: none; */
    outline: none;
    cursor: pointer;
}

.job_overview {
    border: 2px solid rgba(229, 229, 229, 1);
    border-radius: 10px;
    margin-top: 20px;
}

.job_overview .header {
    display: flex;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
    height: 55px;
    align-items: center;
    /* margin-left: 10px; */
    justify-content: space-evenly;
    color: rgba(62, 59, 59, 1);
}

.job_overview .header .created_by {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.job_overview .header .created_by .date {
    font-size: 10px;
}

.job_overview .overview {
    display: flex;
    justify-content: space-evenly;
    height: 90px;
    align-items: center;
}


.job_overview .overview .box {
    padding: 10px;
    display: flex;
    height: 60px;
    gap: 10px;
    border: 2px solid rgba(229, 229, 229, 1);
    border-radius: 6px;

}

.job_overview .overview .box .image {
    /* margin: 20px; */
    background-color: rgba(231, 240, 250, 1);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.job_overview .overview .box .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.job_overview .overview .box .details .val {
    color: rgba(24, 25, 28, 1);
    font-weight: 500;
    font-size: 14px;
}

.job_overview .overview .box .details .query {
    color: rgba(118, 127, 140, 1);
    font-weight: 400;
    font-size: 10px;
}

.matching-status {
    display: flex;
    justify-content: space-around;
}

.matched,
.not-matched {
    margin-top: 20px;
    /* height: 10px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    max-width: 45%;
}

.matched .icon-flex,
.not-matched .icon-flex {
    display: flex;
    gap: 20px;

}

.matched .icon-flex .details .query,
.not-matched .icon-flex .details .query {
    color: rgba(0, 128, 0, 1);
    font-size: 10px;
    font-weight: 500;
    margin-top: 5px;
}

.matched .icon-flex .details .items,
.not-matched .icon-flex .details .items {
    margin-top: 5px;
    font-weight: 500;
    font-size: 13px;
}

.not-matched .icon-flex .details .query {
    color: rgba(188, 105, 105, 1);
}


.current_stage {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    font-size: 14px;
    font-weight: 400;
}

.current_stage .status {
    display: flex;
    border: 2px solid rgba(215, 215, 215, 1);
    height: 30px;
    width: 80px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: rgba(24, 25, 28, 1);
    font-weight: 500;
    font-size: 15;
    margin-top: -5px;
}

.current_stage .ctc {
    color: rgba(75, 147, 231, 1);
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
}

.current_status_bar {
    margin-top: 15px;
    width: 100%;
    border: 2px solid rgba(198, 198, 198, 1);
    display: flex;
    gap: 20px;
    padding: 5px 15px;
    border-radius: 7.6px;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
}

.current_status_bar::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
}

.item {
    min-width: 120px;
    font-size: 12px;
    /* Fixed width for each item */
    text-align: center;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    /* background-color: lightgray; */
    transition: 0.3s;
}

.item:hover {
    background-color: darkgray;
}

.selected {
    background-color: rgba(75, 147, 231, 1);
    color: white;
}

.interviews_section {
    margin-top: 20px;
    border: 2px solid rgba(230, 230, 230, 1);
    border-radius: 6px;
}

.interviews_section .interviews_title {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    border-bottom: 2px solid rgba(230, 230, 230, 1);
    font-size: 14px;
    font-weight: 500;
}

.interviews_section .interviews_title .scheduled_by {
    color: rgba(24, 25, 28, 1);
    font-weight: 400;
}

.interviews_section .main_section {
    margin: 15px 25px;
    display: flex;

}

.interviews_section .main_section .next_interview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.interviews_section .main_section .next_interview .interview_type {
    display: flex;
    justify-content: space-between;
    color: rgba(24, 25, 28, 1);
    font-weight: 500;
}

.interviews_section .main_section .next_interview .interview_type .interview_mode {
    background-color: rgba(249, 243, 229, 1);
    border-radius: 8px;
    padding: 5px 8px;
    color: rgba(173, 174, 175, 1);
    font-weight: 500;
    font-size: 9px;
}

.interviews_section .main_section .next_interview .interview_date {
    margin-top: 5px;
    color: rgba(24, 25, 28, 1);
    font-weight: 500;
    font-size: 11px;
}

.interviews_section .main_section .next_interview .link {
    margin-top: 5px;
    color: rgba(173, 174, 175, 1);
    font-size: 11px;
}


.main_section .assigned_recruiter {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main_section .assigned_recruiter img {
    margin-top: 7px;
}

.main_section .interview_panel {
    margin-left: 30px;
}

.main_section .interview_panel .multiple {
    margin-top: 7px;
}

.main_section .interview_panel .multiple .img-1,
.img-2 {
    margin-right: -15px;
}




/* component 3 */

.comments-side {
    margin-top: 30px;
    width: 25%;
    margin-left: 10px;
}

.comments-side .comments {
    border: 2px solid rgba(230, 230, 230, 1);
    padding-bottom: 15px;
    border-radius: 6px;
}

.comments-side .comments .header {
    display: flex;
    gap: 20px;
    padding-left: 15px;
    color: rgba(80, 80, 80, 1);
    font-weight: 700;
    font-size: 16px;
    padding: 15px 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(230, 230, 230, 1);
}

.comments-side .comments .header .count {
    background-color: rgba(0, 91, 190, 1);
    padding: 2px 13px;
    margin-top: -3px;
    border-radius: 9px;
    color: rgba(255, 255, 255, 1);
}

.comments-side .comments .component {
    margin-top: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.comments-side .comments .component img {
    height: 40px;
    width: 40px;
}

.comments-side .comments .component .details {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.comments-side .comments .component .details .name {
    color: rgba(79, 79, 79, 1);
    font-size: 14px;
    font-weight: 600;
}

.comments-side .comments .component .details .feedback {
    color: rgba(148, 148, 152, 1);
    font-size: 12px;
    font-weight: 400;
}





/* component 4 */


.not-updated {
    max-height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 50px; */
    justify-content: center;
}

.not-updated .section {
    /* width: 95%; */
    border-radius: 5px;
    padding: 20px 10px;
    background-color: white;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.not-updated .heading {
    color: rgba(0, 91, 190, 1);
    font-size: 15px;
    font-weight: 500;
}

.not-updated .small-heading {
    font-size: 13px;
    color: rgb(179, 144, 79);
}