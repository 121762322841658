.rec-task-tracking {
    display: flex;
    margin-top: 50px;
    margin-left: 30px;
    color: rgba(23, 26, 31, 1);
}

.rec-task-tracking .left {
    width: 75%;
}

.rec-task-tracking .left .main-components {
    display: flex;
    gap: 20px;
}

.rec-task-tracking .left .main-components .section {
    /* height: 150px; */
    width: 180px;
    background-color: aqua;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 16px;
}

.rec-task-tracking .left .main-components .section .top {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: rgba(50, 55, 67, 1);
    align-items: center;
}

.rec-task-tracking .left .main-components .section .top img {
    height: 40px;
}

.rec-task-tracking .left .main-components .section .count {

    font-size: 30px;
}

.rec-task-tracking .left .main-components .section .thumbnail {
    color: rgba(111, 119, 135, 1);
    font-size: 10px;
}


.rec-task-tracking .left .main-components .new {
    background-color: rgba(241, 248, 253, 1);
    color: rgba(55, 154, 230, 1);
}

.rec-task-tracking .left .main-components .in-progress {
    background-color: rgba(245, 247, 250, 1);
    color: rgba(34, 204, 178, 1);
}

.rec-task-tracking .left .main-components .completed {
    background-color: rgba(245, 248, 250, 1);
    color: rgba(35, 30, 59, 1);
}

.rec-task-tracking .left .main-components .missed-deadline {
    background-color: rgba(250, 245, 245, 1);
    color: rgba(235, 0, 39, 1);
}

.rec-task-tracking .left .job-tracking {
    margin-left: 10px;
    margin-right: 10px;
}

.rec-task-tracking .left .job-tracking .title {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.rec-task-tracking .left .job-tracking .title .heading {
    font-size: 14px;
}

.rec-task-tracking .left .job-tracking .title .filter {
    background-color: rgba(243, 244, 246, 1);
    padding: 10px 15px;
    font-size: 10px;
    border-radius: 5px;
}

.rec-task-tracking .left .job-tracking .sec-2 {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

}

.rec-task-tracking .left .job-tracking .sec-2 .filter1 {
    background-color: white;
    color: rgba(22, 129, 255, 1);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    margin-left: 10px;
}

.rec-task-tracking .left .job-tracking .sec-2 .filter1 .item {
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 18px;
}

.rec-task-tracking .left .job-tracking .sec-2 .sort {
    font-size: 10px;
    background-color: rgba(243, 244, 246, 1);
    padding: 5px 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.rec-task-tracking .left .job-tracking .job-list {
    margin-top: 20px;
    width: 100%;
    /* border-radius: 16px; */
}

.rec-task-tracking .left .job-tracking .job-list .row {
    width: 95%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    padding: 10px 15px;
    align-items: center;
    font-size: 14px;
    border: 1px solid rgba(222, 225, 230, 1);
}

.rec-task-tracking .left .job-tracking .job-list .row>div {
    text-align: left;
    padding: 5px;
}

.rec-task-tracking .left .job-tracking .job-list .row .priority {
    padding: 5px 5px;
    border-radius: 14px;
    font-size: 12px;
    text-align: center;
    width: 60px;
}

.rec-task-tracking .left .job-tracking .job-list .row .High {
    background-color: rgba(253, 242, 242, 1);
    color: rgba(222, 59, 64, 1);
}

.rec-task-tracking .left .job-tracking .job-list .row .Medium {
    background-color: rgba(254, 249, 238, 1);
    color: rgba(152, 105, 12, 1);
}

.rec-task-tracking .left .job-tracking .job-list .row .Low {
    background-color: rgba(238, 253, 243, 1);
    color: rgba(17, 123, 52, 1);
}

.rec-task-tracking .left .job-tracking .job-list .row .last-date {
    color: rgba(144, 149, 161, 1);
    font-size: 12px;
    display: flex;
    gap: 3px;
}

.rec-task-tracking .right {
    margin-left: 10px;
    width: 24%;
}

.rec-task-tracking .right .title {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
}

.rec-task-tracking .right .title .heading {}

.rec-task-tracking .right .title .view-all {
    font-size: 14px;
    font-weight: 400;
    color: rgba(35, 30, 59, 1);
    cursor: pointer;
}

.rec-task-tracking .right .list {
    display: flex;
    gap: 0px;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    scrollbar-width: thin;
    scrollbar-color: lightgray transparent;
}

.rec-task-tracking .right .list::-webkit-scrollbar {
    height: 2px;
}

.rec-task-tracking .right .list::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}

.rec-task-tracking .right .list .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
}

.rec-task-tracking .right .list .profile img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: pink;
}

.rec-task-tracking .right .list .profile.add-more {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer;
}

.rec-task-tracking .right .list .profile.add-more .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
}

.recent-activities {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9;
}


.recent-activities .title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}



.activity {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.activity:last-child {
    border-bottom: none;
}

.activity-item {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.activity-item img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: lightgray;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.details .name {
    font-weight: 400;
    font-size: 14px;
}

.details .thumbnail,
.details .candidate,
.details .job {
    font-size: 12px;
    color: rgba(86, 93, 109, 1);
}