.job-postings-list-main .action-btns{
    display: flex;
    justify-content: space-evenly;
}

.job-postings-list-main .action-btns a{
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    padding: 5px 10px;
    border-radius: 5px;
}