.agency-candidates{
  width: 98%;
  margin: 0 auto;
  /* background-color: lightblue; */

  .row1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 80px;
    padding:0px 20px ;
  }

  .row2{
    display: flex;
    flex-direction: column;
  }
}

.row1{

  .search-inputbtn-container{
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .search-btn{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #1681FF;
    border-radius: 8px ;
    width: 100px;
    height: 40px;
  }
  .dropdown{
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.row2{
  width: 100%;
padding: 20px;
  .results-clearall{
    display: flex;
    justify-content: space-between;
  }
}

.results-clearall .results{
  display: flex;
  gap: 10px;
  
}

.search-inputbtn-container{

  .input{
    outline: none;
    /* border: none; */
    margin: none;
    border-radius: 20px;
    height: 35px;
    width: 200px;
    /* background-color: lightcoral; */
    text-indent: 10px;
    border: 1px solid gray;
    
  }
}
.search-btn{
  h3{
    margin: 0px;
    font-weight: 400;
    color: white;
    font-size: 13px;
  }
  
}