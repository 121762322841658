.cand-card-container{
  /* background-color: lightpink; */
  width: 100%;
  display: flex;
  margin: 20px auto;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #BCC1CA;
  border-radius: 10px;
}
.profile-content-container{
  display: flex;
  /* justify-content: ; */
  align-items: center;
}