.calendar-manage-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.calendar-manage-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.events-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.events-table thead {
    background-color: #007bff;
    color: #fff;
}

.events-table th,
.events-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.events-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.events-table tr:hover {
    background-color: #ddd;
}

.manage-events-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.manage-events-button:hover {
    background-color: #0056b3;
}
