.job-responses-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .search-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
  }

  .field-container{
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    }
    .field-text-container{
      display: flex;
      flex-direction: column;

    }
}
.field-search-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field-search-container .search-input{
  border: none;
  margin: none;
  outline: none;
  font-size: 15px;
}


.job-responses-container .search-container {
  height: 35px;
  width: 250px;

  /* width: 500px; */
  border-radius: 20px;
  display: flex;
  background-color: #0000000D;
  /* padding: 5px; */
  /* gap: 5px; */
  padding-left: 15px;
  img{
    width: 20px;
  }
  
}
.search-container input{
  background: none;
  text-indent: 10px;
  height: 100%;
  width: 100%;
}
.field-text-container .first-row{
  
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  h3{
    color: #171A1F;
    font-size: 20px;
    font-weight: 600;
    padding: 0px;
    margin: 6px;
  }
}
.field-text-container .second-row{
  display: flex;
  /* flex-direction: column; */
gap: 10px;  
.img-text{
  display: flex;
  gap: 5px;
  color: #565E6C;
  font-weight: 500;
  size: 12px;
}
}

.all-interviews-container{
  margin-top: 50px;
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
}

.interview-container {
  padding: 10px;
  width: 250px;
  border: 1px solid #BCC1CA;
  border-radius: 10px 10px 0px 0px ;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  h3{
    color: #323842;
  text-align: left;
  }
}



